import styled from "styled-components";

export const AbnormalLabResultStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter_health_top {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_filter_health {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .g_filter_label {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .s_label_filter {
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .box_filer_date_top {
        display: flex;
        align-items: center;
        column-gap: 16px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .bw_date {
          width: 113px;
        }
      }
    }
  }
  .box_date {
    border-radius: 8px;
    margin-top: 25px;
    display: flex;
    gap: 12px;
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    .date_blue {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }

  .table_top {
    margin-top: 24px;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};

    .left_men_layout {
      margin-bottom: 30px;
    }

    .title_table {
      display: flex;
      column-gap: 5px;
      .text_blue {
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }

    .text_no {
      padding-right: 12px;
      padding-top: 6px;
      text-wrap: nowrap;
    }
    .category_detail {
      display: flex;
      padding: 2px;
    }
    .category_ranking {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      padding: 0px 15px;

      .category_title {
        display: flex;
        align-items: flex-start;
        min-width: 160px;
        margin-right: 5px;
        padding: 3px 0;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};

        .detail {
          display: flex;
          row-gap: 3px;
          flex-direction: column;
        }
      }

      .category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 98px;
      }
      .blue_box {
        padding: 4px 11px;
        background: ${({ theme }) => theme.COLORS.BLUE_26};
        display: flex;
        column-gap: 5px;
        align-items: center;
        height: 22px;
        width: 93px;
        .text_red {
          color: ${({ theme }) => theme.COLORS.RED_8};
          text-wrap: nowrap;
        }
      }
    }
  }

  .box_age_groups {
    display: flex;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  }

  .table_layout {
    overflow: auto;
    padding: 2px;
  }
  .item_age {
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;

    &.pdl5 {
      padding-left: 8px;
      border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_25};
    }
  }
`;
