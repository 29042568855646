import styled from "styled-components";

export const HealthCheckDepartmentStyled = styled.div`
  .box_dep_health_check {
    padding: 24px;
    /* padding-top: 50px; */
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    /* display: flex; */
    column-gap: 50px;
    flex-wrap: wrap;

    .top_dep {
      display: flex;
      /* align-items: center; */
      flex-wrap: wrap;
      row-gap: 5px;
      align-items: flex-start;
      .left_men_layout {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .right_men_layout {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        row-gap: 9px;
      }
    }
    .group_graph_zone {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      align-items: center;
      .body_graph_dep_health {
        flex: 1;
        position: relative;
        min-width: 400px;
        max-width: 1000px;

        .top_dep_title_g {
          color: ${({ theme }) => theme.COLORS.GRAY_37};
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          font-weight: 300;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70px;
          position: absolute;
          /* top: -12px; */
          /* left: 72px;
           */
          left: 172px;
        }
        .right_dep_title_g {
          color: ${({ theme }) => theme.COLORS.GRAY_37};
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
          font-weight: 300;
          position: absolute;
          right: -90px;
          bottom: 41px;
        }
      }
      /* .group_box_blue { */
      /* display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.COLORS.BLUE_32}; */

      .box_blue {
        background: ${({ theme }) => theme.COLORS.BLUE_32};
        border-radius: 8px;
        padding: 8px 18px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        width: 150px;
        .date_blue {
          display: flex;
          column-gap: 16px;
          margin-top: 12px;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          .c_box {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            height: 83px;
            flex-wrap: wrap;
            width: 100%;
            align-content: space-between;
          }
        }
        /* } */
      }
    }

    .right_dep_men_layout {
      display: flex;
      flex-direction: column;
      row-gap: 9px;
      /* width: 224px; */
      /* padding-top: 26px; */
      flex-shrink: 0;
    }
  }

  .cate_label {
    background: pink;
  }

  .year {
    color: ${({ theme }) => theme.COLORS.GRAY_23};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
    position: absolute;
    /* top: -12px; */
    left: 172px;
    bottom: 53px;
    /* z-index: 99; */
    /* gap: 1.5px; */

    .text {
      height: ${({ dataLength }) => (!dataLength ? "187px" : "57.5px")};
      display: flex;
      align-items: center;
      flex-direction: column;
      /* justify-content: space-evenly; */
      justify-content: center;
      gap: ${({ dataLength }) => (!dataLength ? "10px" : 0)};
    }
  }
`;
