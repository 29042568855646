import styled from "styled-components";

export const HealthCheckOverviewStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section_introduce_layout {
    margin-bottom: 22px;
  }
  .section_chart_layout {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  .filter_health_top {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    /* margin-top: 40px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_filter_health {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .g_filter_label {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .s_label_filter {
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .box_filer_date_top {
        display: flex;
        align-items: center;
        column-gap: 16px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .bw_date {
          width: 113px;
        }
      }
    }
  }
  .box_date {
    border-radius: 8px;
    margin-top: 25px;
    display: flex;
    gap: 12px;
    color: ${({ theme }) => theme.COLORS.GRAY_24};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    .date_blue {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }
  }

  .box_2_g {
    margin-top: 24px;
    display: flex;
    column-gap: 14px;
    overflow: auto;
    .g_w424 {
      max-width: 424px;
      min-width: 275px;
      width: 35%;
    }
    .g_full {
      flex: 1;
    }
  }
  .full_w_100 {
    margin-top: 16px;
    &.mt40 {
      margin-top: 40px;
    }
    .mid_title {
      color: ${({ theme }) => theme.COLORS.GRAY_23};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
    }
    .mid_table_show {
      margin-top: 24px;
      .des_sub {
        margin-top: 16px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
  }
`;
