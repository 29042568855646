import { PercentOfEmployeHealthCheckStyled } from "./styled";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import theme from "styles/theme.json";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { Typographys } from "components";

const PercentOfEmployeHealthCheck = ({ data }) => {
  const [_data, _setData] = useState([]);

  useEffect(() => {
    if (data) {
      let dumpData = [];
      let tempDepartments = [];
      let uniqueCounts = [];

      data?.abnormal?.forEach((e) => {
        let x;
        if (e.lab === "bmi_abnormal_count") {
          x = "ดัชนีมวลกาย";
        } else if (e.lab === "bp_abnormal_count") {
          x = "ความดันโลหิต";
        } else if (e.lab === "fbs_abnormal_count") {
          x = "น้ำตาลในเลือด";
        } else if (e.lab === "kidney_abnormal_count") {
          x = "การทำงานของไต";
        } else if (e.lab === "liver_abnormal_count") {
          x = "สมรรถภาพตับ";
        } else if (e.lab === "uric_abnormal_count") {
          x = "สารกรดยูริก";
        } else if (e.lab === "cholesterol_abnormal_count") {
          x = "คอเลสเตอรอล";
        } else if (e.lab === "tg_abnormal_count") {
          x = "ไตรกลีเซอไรด์";
        } else if (e.lab === "ldl_abnormal_count") {
          x = "ไขมันเลว";
        } else if (e.lab === "hdl_abnormal_count") {
          x = "ไขมันดี";
        }

        uniqueCounts = [
          ...new Set(
            e.departments.map((department) =>
              Number(department[`${e.lab}_count`])
            )
          ),
        ]
          .sort((a, b) => b - a)
          .slice(0, 3);

        tempDepartments = uniqueCounts
          .map((unique) => {
            let departmentName = [];
            departmentName = e.departments
              .filter(
                (department) => Number(department[`${e.lab}_count`]) === unique
              )
              .map((department) => department.department_name);
            return [departmentName];
          })
          .flat(); // Flatten the array if there are multiple matches for a unique count

        dumpData.push({
          x: x,
          y: Number(
            ((Number(e?.total_count) / data.checkup_count) * 100).toFixed(2)
          ),
          status: "ผิดปกติ",
          amount: RenderCommaMoney(Number(e?.total_count).toFixed(0)),
          totalAmount: RenderCommaMoney(Number(data.checkup_count).toFixed(0)),
          departments: tempDepartments,
        });
      });

      _setData(dumpData.sort((a, b) => b.y - a.y));
    }
  }, [data]);

  const renderHeight = () => {
    let tempHight = 136;
    if (_data && _data.length > 1) {
      tempHight = 79.6 + 56.4 * _data.length;
    }

    return tempHight;
  };

  return (
    <PercentOfEmployeHealthCheckStyled>
      <div className="box_percent_emp_health_check">
        <div className="left_men_layout">
          <Typographys.TopBottom
            theme_left_align
            label="สัดส่วนค่าผลแล็บผิดปกติของพนักงาน"
            labelBottom="Abnormal of Employee"
            fontSize={theme.FONT.SIZE.S18}
            fontSizeLabelButtom={theme.FONT.SIZE.S14}
            fontFamilyTop={theme.FONT.STYLE.BOLD}
            fontFamilyBottom={theme.FONT.STYLE.REGULAR}
          />
        </div>
        <div className="body_graph_percent_emp_health">
          {!data || (data && data.checkup_count === 0) ? (
            <div className="lbl_empty">ไม่มีข้อมูล</div>
          ) : (
            <>
              <div className="top_percent_emp_title_g">หมวดผลแล็บ</div>
              <div className="right_dep_title_g">จำนวนร้อยละ (%)</div>
              <div></div>
              <Chart
                type="bar"
                height={renderHeight()}
                options={{
                  chart: {
                    stacked: true,
                    fontFamily: theme.FONT.STYLE.REGULAR,
                    toolbar: {
                      show: false,
                    },
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      var dataToolTip =
                        w.globals.initialSeries[seriesIndex].data[
                          dataPointIndex
                        ];

                      var tooltipContent =
                        '<div class="box_hover">' +
                        '<div class="title_text">ผลแล็บ' +
                        dataToolTip.x +
                        "</div>" +
                        '<div class="box_detail">' +
                        '<div class="big_text_s">' +
                        'พบความผิดปกติทั้งหมด : <div class="text_red">' +
                        dataToolTip.y +
                        "%</div>" +
                        "</div>" +
                        '<div class="text_datail">' +
                        "(จำนวน " +
                        dataToolTip.amount +
                        " คน จากจำนวนทั้งหมด " +
                        dataToolTip.totalAmount +
                        " คน)" +
                        "</div>" +
                        "</div>" +
                        '<div class="box_detail">' +
                        '<div class="big_text_s">แผนกที่พบความผิดปกติมากที่สุด</div>' +
                        '<div class="text_datail">';

                      dataToolTip.departments.forEach((e, i) => {
                        tooltipContent +=
                          '<div class="departments">' +
                          (i + 1) +
                          " . " +
                          '<div class="departments text"> ' +
                          e?.join(", ") +
                          "</div>" +
                          "</div>";
                      });

                      tooltipContent += "</div>";

                      return tooltipContent;
                    },
                  },
                  grid: {
                    xaxis: {
                      lines: {
                        show: false,
                      },
                    },
                    yaxis: {
                      lines: {
                        show: true,
                      },
                    },
                  },

                  dataLabels: {
                    enabled: true,
                    position: "center",
                    enabledOnSeries: undefined,
                    formatter: function (val, opts) {
                      return `${val}%`;
                    },
                    style: {
                      fontSize: "14px",
                      fontFamily: theme.FONT.STYLE.REGULAR,
                      fontWeight: 400,
                    },
                  },
                  xaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: {
                      formatter: function (value) {
                        return `${value}`;
                      },
                      style: {
                        fontSize: "12px",
                        colors: theme.COLORS.BLACK_11,
                        fontFamily: theme.FONT.STYLE.REGULAR,
                        cssClass: "apexcharts-xaxis-label",
                      },
                    },
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: theme.COLORS.BLACK_10,
                    },
                  },
                  yaxis: {
                    axisBorder: {
                      show: true,
                      color: "#5C5C5C",
                    },
                    labels: {
                      hideOverlappingLabels: false,
                      style: {
                        colors: theme.COLORS.BLACK_11,
                        fontSize: "12px",
                        fontFamily: theme.FONT.STYLE.REGULAR,
                        cssClass: "apexcharts-xaxis-label",
                      },
                    },
                  },
                  colors: ["#EC5E49"],
                  fill: {
                    opacity: 1,
                  },

                  plotOptions: {
                    bar: {
                      horizontal: true,
                      barHeight: "40%",
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "Series 1",
                    data: _data,
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </PercentOfEmployeHealthCheckStyled>
  );
};

export default PercentOfEmployeHealthCheck;
