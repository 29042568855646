import styled from "styled-components";

export const GraphTemplateStyled = styled.div`
  padding: 24px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
  width: 100%;
  max-width: 613px;
  box-shadow: 0px 3px 6px 0px #5f5f5f0a;
  .container_graph_display {
    /* min-width: 544px; */
    .top_layout {
      display: flex;
      /* align-items: center; */
      flex-wrap: wrap;
      row-gap: 5px;
      align-items: flex-start;
      .left_men_layout {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .right_men_layout {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        column-gap: 9px;
      }
    }
  }

  .body_graph {
    position: relative;
    margin-top: 8px;
    padding: 10px 20px;
    position: relative;
    .top_title_g {
      color: ${({ theme }) => theme.COLORS.GRAY_37};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 53px;
      position: absolute;
    }
    .right_title_g {
      color: ${({ theme }) => theme.COLORS.GRAY_37};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      font-weight: 300;
      position: absolute;
      right: -20px;
      bottom: 53px;
    }
    .lbl_empty {
      display: flex;
      justify-content: center;
      height: 300px;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }

  .box_hover {
    padding: 12px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_8};

    .text_datail {
      color: ${({ theme }) => theme.COLORS.GRAY_37};
    }
    .title {
      display: flex;
      column-gap: 5px;
      align-items: center;
      .name_title {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
      }
      .round {
        height: 12px;
        width: 12px;
        border-radius: 50px;
        &.all {
          background: rgb(116, 90, 157);
        }

        &.female {
          background: rgb(218, 86, 149);
        }

        &.male {
          background: rgb(67, 98, 164);
        }
      }
    }
  }
`;
