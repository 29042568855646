import styled from "styled-components";

export const AgeDistributionStyled = styled.div`
  .box_age_dis {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
    .top_age_dis {
      display: flex;
      /* align-items: center; */
      flex-wrap: wrap;
      row-gap: 5px;
      align-items: flex-start;
      .left_men_layout {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .right_men_layout {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        row-gap: 9px;
      }
    }
    .body_graph {
      position: relative;
      margin-top: 8px;
      padding: 0px 20px;
      position: relative;
      .top_title_g {
        color: ${({ theme }) => theme.COLORS.GRAY_37};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        font-weight: 300;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 44px;
      }
      .right_title_g {
        color: ${({ theme }) => theme.COLORS.GRAY_37};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        font-weight: 300;
        position: absolute;
        right: 0;
        bottom: 29px;
      }
      .lbl_empty {
        display: flex;
        justify-content: center;
        height: 300px;
        align-items: center;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
      }
    }
  }
`;
