import React, { useState, useEffect } from "react";
import { AbnormalLabResultStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { adminService } from "apiServices";
import {
  Loading,
  BGLoading,
  Inputs,
  TableCustom,
  Typographys,
} from "components";
import theme from "styles/theme.json";
import { PercentOfEmployeHealthCheck } from "widgets";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";

const AbnormalLabResult = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBgLoading, setIsBgLoading] = useState();

  const { companySelectedRedux } = useSelector((state) => state);
  const [dataAbnormal, setDataAbnormal] = useState();
  const [dataTopFiveMost, setDataTopFiveMost] = useState([]);
  const [lastCheckup, setLastCheckup] = useState();

  useEffect(() => {
    scrollToTopPageFN();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempDate = moment().tz("Asia/Bangkok").format(`YYYY-01-01`);
    setIsLoading(true);
    fetchAbnormalData(`?checkup_date=${tempDate}`);
    fetchTopFiveMost(tempDate);
    fetchCheckUpLastDate();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCheckUpLastDate = async (value) => {
    let res =
      await adminService.GET_CHECK_UP_LAST_DATE_STATISTICS_BY_COMPANY_ID(
        companySelectedRedux?.id
      );

    if (res && res.success) {
      setLastCheckup(res.data?.last_checkup);
    }
  };

  const fetchTopFiveMost = async (value) => {
    let res = await adminService.GET_HEALTH_CHECKUP_TOP_FIVE_MOST_ABNORMAL(
      companySelectedRedux?.id,
      {
        checkup_date: value,
      }
    );

    if (res && res.success) {
      let groupedData = [];
      let top5Groups = [];

      groupedData = Object.values(
        res.data.reduce((acc, item) => {
          if (!acc[item.percentage]) acc[item.percentage] = [];
          acc[item.percentage].push(item);
          return acc;
        }, {})
      );

      top5Groups = groupedData
        .sort((a, b) => b[0].percentage - a[0].percentage)
        .slice(0, 5);

      setDataTopFiveMost(top5Groups);
    }
  };

  const fetchAbnormalData = async (stringQuery) => {
    let res = await adminService.GET_HEALTH_CHECKUP_ABNORMAL(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      setDataAbnormal(res.data);
      setIsLoading(false);
      setIsBgLoading(false);
    }
  };

  const handleChangDate = (e) => {
    let tempDate = moment(e).tz("Asia/Bangkok").format(`YYYY-01-01`);
    setIsBgLoading(true);
    fetchAbnormalData(`?checkup_date=${tempDate}`);
    fetchTopFiveMost(tempDate);
  };

  return (
    <AbnormalLabResultStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="filter_health_top">
            <div className="box_filter_health">
              <div className="g_filter_label">
                <div>ภาพรวมพนักงานที่มีผลตรวจสุขภาพประจำปี</div>
                <div className="s_label_filter">
                  Employees annual health check overview
                </div>
              </div>
            </div>
            <div className="box_filter_health">
              <div className="box_filer_date_top">
                <div>แสดงสถิติข้อมูลปี</div>
                <div className="bw_date">
                  <Inputs.InputDatePikerCustom
                    theme_year
                    dateFormat="yyyy"
                    minDate={new Date("2021-01-01")}
                    maxDate={new Date()}
                    onChangeCustom={(e) => handleChangDate(e)}
                    showYearPicker
                    iconColor="#002366"
                    formatAD
                    initialValues={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box_date">
            <div>ข้อมูลล่าสุด ณ วันที่</div>
            <div className="date_blue">
              {`${moment(lastCheckup).locale("en").format("DD MMMM YYYY")} `}
            </div>
          </div>
          <div className="full_w_100 mt40">
            <PercentOfEmployeHealthCheck data={dataAbnormal && dataAbnormal} />
          </div>
          <div className="full_w_100 mt40">
            <div className="table_top">
              <div className="left_men_layout">
                <Typographys.TopBottom
                  theme_left_align
                  label="ค่าผลแล็บผิดปกติของพนักงาน ที่พบมากที่สุด 5 อันดับแรก"
                  labelBottom="Top 5 Most Abnormal of Employee"
                  fontSize={theme.FONT.SIZE.S18}
                  fontSizeLabelButtom={theme.FONT.SIZE.S14}
                  fontFamilyTop={theme.FONT.STYLE.BOLD}
                  fontFamilyBottom={theme.FONT.STYLE.REGULAR}
                />
              </div>
              <div className="table_layout">
                <TableCustom.TableNormal
                  theme_health_check_ranking
                  columns={columns}
                  data={dataTopFiveMost}
                  useNoIn={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </AbnormalLabResultStyled>
  );
};

const columns = [
  {
    title: "อันดับหมวดผลแล็บ",
    field: "lab",
    headerStyle: {
      display: "flex",
      alignItems: "left",
      justifyContent: "center",
      minWidth: "304px",
      maxWidth: "304px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      // height: "40px",
      justifyContent: "left",
      minWidth: "289px",
      maxWidth: "304px",
    },
    render: ({ origin, value }) => (
      <div className="category_ranking">
        <div className="category_title">
          <div className="text_no">อันดับที่ {origin.no}</div>
          <div className="detail">
            {origin?.data?.map((e, i) => (
              <div className="category_detail">
                <div className="category">
                  {e?.lab === "bmi_abnormal_count"
                    ? "ดัชนีมวลกาย"
                    : e?.lab === "cholesterol_abnormal_count"
                    ? "คอเลสเตอรอล"
                    : e?.lab === "ldl_abnormal_count"
                    ? "ไขมันเลว"
                    : e?.lab === "uric_abnormal_count"
                    ? "สารกรดยูลิค"
                    : e?.lab === "bp_abnormal_count"
                    ? "ความดันโลหิต"
                    : e?.lab === "fbs_abnormal_count"
                    ? "น้ำตาลในเลือด"
                    : e?.lab === "tg_abnormal_count"
                    ? "ไตรกลีเซอไรด์"
                    : e?.lab === "hdl_abnormal_count"
                    ? "ไขมันดี"
                    : e?.lab === "kidney_abnormal_count"
                    ? "การทำงานของไต"
                    : e?.lab === "liver_abnormal_count"
                    ? "สมรรถภาพตับ"
                    : e?.lab}
                </div>
                <div className="blue_box">
                  จำนวน <div className="text_red"> {e?.percentage} % </div>{" "}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
  },
  {
    title: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="title_table">
          <div>ช่วงอายุที่ผิดปกติสูงสุด เรียงลำดับจาก</div>
          <div className="text_blue">มากไปน้อย</div>
        </div>
        (โดยเรียงข้อมูลมากสุดคือด้านซ้าย น้อยสุดคือด้านขวา)
      </div>
    ),
    field: "age",
    headerStyle: {
      display: "flex",
      alignItems: "left",
      justifyContent: "center",
      minWidth: "400px",
    },
    style: {
      display: "flex",
      justifyContent: "left",
      minWidth: "100px",
      flexDirection: "column",
    },
    render: ({ origin, value }) =>
      origin.data.map((e, index) => (
        <div className="box_age_groups ">
          {e.top_age_groups?.map((item, i) => {
            return (
              <div
                style={{
                  minWidth: "36px",
                  display: "flex",
                  // width: "90%",
                }}
                className={`item_age ${i >= 1 ? "pdl5" : ""}`}
                data-tip
                data-for={`tooltipName${item.age_group}_${origin.no}_${index}_${i}`}
              >
                <div
                  style={{
                    minWidth: "36px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.age_group === 60
                    ? "60 ปีขึ้นไป"
                    : item?.age_group === 19
                    ? "น้อยกว่า 20 ปี"
                    : `${item?.age_group.replace(/_/g, "-")} ปี`}
                </div>

                <ReactTooltip
                  id={`tooltipName${item.age_group}_${origin.no}_${index}_${i}`}
                  delayShow="800"
                >
                  <span> {item.count} คน</span>
                </ReactTooltip>
              </div>
            );
          })}
        </div>
      )),
  },
];

export default AbnormalLabResult;
