import React, { useEffect, useState } from "react";
import { HealthCheckContainerStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import {
  Blocks,
  Typographys,
  Icons,
  Buttons,
  Tabs,
  Modal,
  TableCustom,
} from "components";
import { AbnormalLabResult, HealthCheckOverview, AbnormalTrend } from "./Tabs";

const HealthCheckContainer = () => {
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    scrollToTopPageFN();
  }, []);

  const handleClickButton = () => {
    setIsShowModal(true);
  };

  const handleClickCloseModal = () => {
    setIsShowModal(false);
  };

  return (
    <HealthCheckContainerStyled>
      <Blocks.Container>
        <div className="title_layout">
          <Typographys.LabelIcon
            theme_standard_label_icon
            label="Health check"
            align="center"
            svgFront={<Icons.HealthCheckOverView />}
          />
          <div className="btn_title_layout">
            <Buttons.BtnNormal
              theme_only_border
              label="เกณฑ์ค่าผลแล็บ"
              fontFamilyTop="noto_sans_thai_medium"
              borderRadius="8px"
              backgroundColor="#fff"
              fontColor="#002366"
              borderColor="#92CBDB"
              svg_front={<Icons.LabCriteria />}
              fontSize="14px"
              onClick={handleClickButton}
            />
          </div>
        </div>
        <Tabs.TabBarSelect tab_canter_tab_bar_select tabHeader={headerTab} />
      </Blocks.Container>
      <Modal
        theme_modal_standard
        isShow={isShowModal}
        handleClickCloseModal={handleClickCloseModal}
      >
        <div className="modal">
          <div className="svg_item ab" onClick={handleClickCloseModal}>
            <Icons.CloseX2 />
          </div>
          <div className="box_title">เกณฑ์ค่าผลแล็บ</div>
          <div className="mid_table_show">
            <TableCustom.TableNormal
              theme_health_check
              columns={columns}
              data={tempData}
            />
            <div className="des_sub">
              *หมวดผลแล็บที่มีหลายผลแล็บย่อย หากผลแล็บย่อยผิดปกติอย่างน้อย 1 ค่า
              ถือว่ามีความผิดปกติหมวดผลแล็บนั้น
            </div>
          </div>
        </div>
      </Modal>
    </HealthCheckContainerStyled>
  );
};

const headerTab = [
  {
    label: "ภาพรวมผลสุขภาพประจำปี",
    labelBottom: "Health check overview",
    component: <HealthCheckOverview />,
    labelTitle: "Company Wellbeing Score",
  },
  {
    label: "การวิเคราะห์ผลแล็บผิดปกติ",
    labelBottom: "Abnormal Lab Result",
    component: <AbnormalLabResult />,
    labelTitle: "Burnout syndrome  Score",
  },
  {
    label: "สถิติเปรียบเทียบผลแล็บผิดปกติ",
    labelBottom: "Abnormal Trend",
    component: <AbnormalTrend />,
    labelTitle: "Burnout syndrome  Score",
  },
];

const columns = [
  {
    title: "หมวดผลแล็บ",
    field: "category",
    headerStyle: {
      display: "flex",
      alignItems: "left",
      // justifyContent: "center",
      minWidth: "80px",
      maxWidth: "240px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      height: "40px",
      justifyContent: "left",
      minWidth: "80px",
      maxWidth: "240px",
    },
    render: ({ origin, value }) => (
      <div
        className="t_tab"
        style={{
          minWidth: "80px",
          maxWidth: "240px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </div>
    ),
  },
  {
    title: "ผลแล็บย่อย",
    field: "sub_category",
    headerStyle: {
      display: "flex",
      // alignItems: "center",
      justifyContent: "left",
      minWidth: "80px",
      maxWidth: "240px",
    },
    style: {
      display: "flex",
      alignItems: "center",
      height: "40px",
      justifyContent: "left",
      minWidth: "80px",
      maxWidth: "240px",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          minWidth: "80px",
          maxWidth: "240px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </div>
    ),
  },
  {
    title: "ค่าปกติ",
    field: "normal_value",
    headerStyle: {
      display: "flex",
      alignItems: "left",
      // justifyContent: "center",
      minWidth: "400px",
    },
    style: {
      display: "flex",
      // alignItems: "center",
      justifyContent: "left",
      minWidth: "400px",
    },
    render: ({ origin, value }) => (
      <div
        style={{
          width: "100%",
        }}
      >
        {origin.sub_normal_value ? (
          <div className="group_value_show">
            <div className="box_value_show">
              <div className="text_warp">{value}</div>
            </div>
            <div className="box_value_show pdl5">
              <div className="text_warp">{origin.sub_normal_value}</div>
            </div>
          </div>
        ) : (
          <div
            style={{ height: "40px", display: "flex", alignItems: "center" }}
          >
            {value}
          </div>
        )}
      </div>
    ),
  },
];

const tempData = [
  {
    category: "ดัชนีมวลกาย",
    sub_category: "BMI",
    normal_value: "18.5-22.9 kg/m2",
  },
  {
    category: "ความดันโลหิต",
    sub_category: "Blood pressure (SBP/DBP)",
    normal_value: "90-129 mmHg /60-79 mmHg",
  },
  {
    category: "น้ำตาลในเลือด",
    sub_category: "FBS",
    normal_value: "70-99 mg/dL",
  },
  {
    category: "คอเลสเตอรอล",
    // sub_category: "Cholesterol",
    normal_value: "< 200 mg/dL",
  },
  {
    category: "ไตรกลีเซอไรด์",
    // sub_category: "Triglyceride",
    normal_value: "<150 mg/dL",
  },
  {
    category: "ไขมันเลว",
    // sub_category: "LDL",
    normal_value: "<100 mg/dL",
  },
  {
    category: "ไขมันดี",
    // sub_category: "HDL",
    normal_value: "Male : ≥40 mg/dL",
    sub_normal_value: "Female : ≥50 mg/dL",
  },
  {
    category: "การทำงานของไต",
    sub_category: "BUN",
    normal_value: "Male ≤50 years : 8.9-20.6 mg/dL",
    sub_normal_value: "Female ≤50 years : 7.0-18.7 mg/dL",
  },
  {
    category: "",
    sub_category: "",
    normal_value: "Male >50 years : 8.4-25.7 mg/dL",
    sub_normal_value: "Female >50 years : 9.8- 20.1 mg/dL",
  },
  {
    category: "",
    sub_category: "SCr",
    normal_value: "Male : 0.73–1.18 mg/dL",
    sub_normal_value: "Female : 0.55–1.02 mg/dL",
  },
  {
    category: "สมรรถภาพตับ",
    sub_category: "SGOT",
    normal_value: "5-34 U/L",
  },
  {
    category: "",
    sub_category: "SGPT",
    normal_value: "0-55 U/L",
  },
  {
    category: "สารกรดยูริก",
    sub_category: "",
    normal_value: "Male : 3.5-7.2 mg/dL",
    sub_normal_value: "Female : 2.6-6.0 mg/dL",
  },
];

export default HealthCheckContainer;
