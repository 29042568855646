import { HealthCheckDepartmentStyled } from "./styled";
import { Blocks, Typographys } from "components";
import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { Inputs } from "components";
import theme from "styles/theme.json";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const HealthCheckDepartment = ({ data }) => {
  const [_category, _setCategory] = useState([]);

  const [_countCheck, _setCountCheck] = useState(1);
  const [lastFiveYears, setLastFiveYears] = useState([]);
  const [_check, _setCheck] = useState([]);
  const [_dataSeries, _setDataSeries] = useState([]);
  const [_colors, _setcolors] = useState(["#002366", "#79A6FF"]);

  useEffect(() => {
    if (data && data.length > 0) {
      let tempCate = [];
      const lastIndex = Object.keys(data[0].years).length - 1;
      const tempYears = Object.keys(data[0].years).map((year, index) => ({
        year: parseInt(year, 10),
        beYear: parseInt(year, 10) + 543, // Convert to Buddhist Era year
        check: index === lastIndex,
      }));
      let tempCheckTrue = tempYears.filter((e) => e.check);

      data.forEach((e) => {
        tempCate.push(`${e.name} `);
      });

      setLastFiveYears(tempYears);
      _setCheck(tempCheckTrue);
      _setCategory(tempCate);

      renderHeight();
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && data.length > 0) {
      let tempDataSeries = [];
      let tempColors = [];

      let test = Object.values(data[0].years);
      Object.keys(test[0]).forEach((name, index) => {
        _check.forEach((year) => {
          let dataPoints = data.map((department) => {
            return department.years[year.year][name] === 0
              ? null
              : department.years[year.year][name];
          });
          tempDataSeries.push({
            name: `${name}-${year.year}`,
            group: year.year,
            data: dataPoints,
          });
        });
      });

      _setDataSeries(tempDataSeries);

      for (let i = 0; i < _check?.length; i++) {
        tempColors.push("#002366");
      }
      // Push color2 twice
      for (let i = 0; i < _check?.length; i++) {
        tempColors.push("#79A6FF");
      }

      _setcolors(tempColors);
    }
  }, [_check]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHeight = () => {
    let tempHight = 254;
    if (data && data.length > 1) {
      tempHight = 79.6 + 56.4 * data.length;
    }

    return tempHight;
  };

  const handlSelectYear = (e, index, value) => {
    let tempCheck = lastFiveYears;
    tempCheck[index].check = e;
    let tempCheckTrue = tempCheck
      .filter((e) => e.check)
      .sort((a, b) => b.year - a.year);
    _setCheck(tempCheckTrue);
    setLastFiveYears(tempCheck);
    _setCountCheck(e === true ? _countCheck + 1 : _countCheck - 1);
  };

  const handleDisabled = (index) => {
    if (_countCheck > 2) {
      return lastFiveYears[index].check === false ? true : false;
    } else if (_countCheck === 1) {
      return lastFiveYears[index].check === true ? true : false;
    } else {
      return false;
    }
  };

  return (
    <HealthCheckDepartmentStyled dataLength={data?.length > 1}>
      <div className="box_dep_health_check">
        <div className="top_dep">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label="แบ่งตามแผนก"
              labelBottom="Department"
              fontSize={theme.FONT.SIZE.S18}
              fontSizeLabelButtom={theme.FONT.SIZE.S14}
              fontFamilyTop={theme.FONT.STYLE.BOLD}
              fontFamilyBottom={theme.FONT.STYLE.REGULAR}
            />
          </div>

          <div className="right_dep_men_layout">
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_health_check
                label="พนักงานที่มีผลสุขภาพประจำปีแล้ว"
                bgColor={theme.COLORS.PURPLE_1}
                showRound
              />
            </div>
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_health_check
                label="พนักงานที่ยังไม่มีผลสุขภาพประจำปี"
                bgColor={theme.COLORS.BLUE_25}
                showRound
              />
            </div>
          </div>
        </div>
        <div className="group_graph_zone">
          <div className="body_graph_dep_health">
            <div className="top_dep_title_g">
              <div>จำนวนปี</div>
            </div>
            <div className="right_dep_title_g">จำนวนพนักงาน</div>
            <div className="year">
              {Array.from({ length: _category?.length }, (_, i) => (
                <div className="text">
                  {_check.map((e, i) => (
                    <div>{e.year}</div>
                  ))}
                </div>
              ))}
            </div>

            <Chart
              type="bar"
              height={renderHeight()}
              options={{
                chart: {
                  stacked: true,
                  // fontFamily: theme.FONT.STYLE.BOLD,
                  toolbar: {
                    show: false,
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#fff"],
                },

                grid: {
                  padding: {
                    left: 75,
                  },
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },

                tooltip: {
                  style: {
                    fontSize: "12px",
                    fontFamily: theme.FONT.STYLE.REGULAR,
                  },
                  y: {
                    formatter: function (
                      value,
                      { series, seriesIndex, dataPointIndex, w }
                    ) {
                      return `${RenderCommaMoney(
                        Number(value).toFixed(0),
                        0
                      )} คน`;
                    },

                    title: {
                      formatter: (seriesName) =>
                        seriesName.split("-")[0] === "no_count"
                          ? "พนักงานที่ยังไม่มีผลสุขภาพประจำปี"
                          : seriesName.split("-")[0] === "yes_count"
                          ? "พนักงานที่มีผลสุขภาพประจำปีแล้ว"
                          : seriesName.split("-")[0],
                    },
                  },
                  x: {
                    formatter: function (
                      value,
                      { series, seriesIndex, dataPointIndex, w }
                    ) {
                      return `${value}  ${
                        series[0][dataPointIndex] +
                        series?.[series?.length / 2]?.[dataPointIndex]
                      } คน`;
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  formatter: function (value, opts) {
                    // return value + " units"; // Custom label
                    return RenderCommaMoney(Number(value).toFixed(0), 0);
                  },
                  style: {
                    // colors: theme.COLORS.WHITE_1,
                    fontSize: "14px",
                    fontFamily: theme.FONT.STYLE.REGULAR,
                    fontWeight: 400,
                  },
                },
                xaxis: {
                  categories: _category,

                  labels: {
                    formatter: function (value) {
                      return RenderCommaMoney(Number(value).toFixed(0), 0);
                    },
                    style: {
                      colors: theme.COLORS.BLACK_6,
                      fontSize: "12px",
                      fontFamily: theme.FONT.STYLE.REGULAR,
                      cssClass: "apexcharts-yaxis-label",
                    },
                  },
                  axisTicks: {
                    show: false,
                  },
                  axisBorder: {
                    show: true,
                    color: "#5C5C5C",
                  },
                },

                yaxis: {
                  axisBorder: {
                    show: true,
                    color: theme.COLORS.PURPLE_1,
                    width: 3,
                  },

                  labels: {
                    offsetX: -55,
                    hideOverlappingLabels: false,
                    style: {
                      colors: theme.COLORS.PURPLE_1,
                      fontSize: "12px",
                      fontFamily: theme.FONT.STYLE.BOLD,
                    },
                  },
                },

                colors: _colors,

                fill: {
                  opacity: 1,
                },

                plotOptions: {
                  bar: {
                    horizontal: true,
                    barHeight: data && data.length > 1 ? "90%" : "40%", // Adjust this value for distance between bars in a group
                    distributed: false,
                    endingShape: "rounded",
                  },
                },
                legend: {
                  show: false,
                },
              }}
              series={_dataSeries}
            />
          </div>
          <div className="group_box_blue" style={{ display: "flex" }}>
            <div className="box_blue">
              <div>เลือกเปรียบเทียบข้อมูล</div>
              <div>ได้สูงสุด 3 ปี</div>
              <div className="date_blue">
                <div className="c_box">
                  {lastFiveYears.map((item, index) => (
                    <Inputs.InputCheckBox
                      theme_dark_blue
                      label={`ปี ${item.year}`}
                      value={item.check}
                      onChangeCustom={(e) => handlSelectYear(e, index, item)}
                      disabled={handleDisabled(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HealthCheckDepartmentStyled>
  );
};

export default HealthCheckDepartment;
