import styled from "styled-components";

export const HealthCheckContainerStyled = styled.div`
  .title_layout {
    margin-bottom: 19px;
    display: flex;
    justify-content: space-between;
  }

  .modal {
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    border-radius: 8px;
    padding: 28px;
    min-width: 1025px;
    max-height: calc(100vh - 85px);
    overflow: auto;

    .svg_item {
      display: flex;
      margin-right: 9px;
      &.ab {
        margin-right: 0px;
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }

    .box_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
    }

    .mid_table_show {
      margin-top: 13px;
      .des_sub {
        margin-top: 16px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      }
    }
  }

  .group_value_show {
    display: flex;
    column-gap: 5px;
    .box_value_show {
      display: flex;
      align-items: center;
      width: 48%;
      height: 40px;
      &.pdl5 {
        padding-left: 8px;
        border-left: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
      }
      .text_warp {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
