// import React from "react";
import React, { useState, useEffect } from "react";
import { HealthCheckOverviewStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { adminService } from "apiServices";
import moment from "moment";
import { useSelector } from "react-redux";
import { Loading, BGLoading, Inputs } from "components";
import {
  TotalEmployeeHealthCheckup,
  AgeDistribution,
  HealthCheckDepartment,
  ExportHealthCheck,
} from "widgets";
import { toast } from "react-toastify";

const HealthCheckOverview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBgLoading, setIsBgLoading] = useState();
  const [selectDate, setSelectDate] = useState(
    moment().tz("Asia/Bangkok").format(`YYYY-01-01`)
  );
  const { companySelectedRedux } = useSelector((state) => state);
  const [data, setData] = useState();
  const [dataDepartment, setDataDepartment] = useState();
  const [lastCheckup, setLastCheckup] = useState();

  useEffect(() => {
    scrollToTopPageFN();
    fetchData(`?checkup_date=${selectDate}`);
    fetchDataDepartment();
    fetchCheckUpLastDate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempDate = moment().tz("Asia/Bangkok").format(`YYYY-01-01`);
    setIsLoading(true);
    fetchData(`?checkup_date=${tempDate}`);
    fetchDataDepartment();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCheckUpLastDate = async (value) => {
    let res =
      await adminService.GET_CHECK_UP_LAST_DATE_STATISTICS_BY_COMPANY_ID(
        companySelectedRedux?.id
      );

    if (res && res.success) {
      setLastCheckup(res.data?.last_checkup);
    }
  };

  const fetchData = async (stringQuery) => {
    let res = await adminService.GET_HEALTH_CHECKUP_SUMMARY(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      setData(res.data);
      setIsLoading(false);
      setIsBgLoading(false);
    }
  };

  const fetchDataDepartment = async (stringQuery) => {
    let res = await adminService.GET_HEALTH_CHECKUP_DEPARTMENT_SUMMARY(
      companySelectedRedux?.id,
      stringQuery
    );

    if (res && res.success) {
      setDataDepartment(res.data);
      setIsLoading(false);
      setIsBgLoading(false);
    }
  };

  const handleChangDate = (e) => {
    let tempDate = moment(e).tz("Asia/Bangkok").format(`YYYY-01-01`);
    setIsBgLoading(true);
    setSelectDate(tempDate);

    fetchData(`?checkup_date=${tempDate}`);
  };

  const handleDownload = async (e) => {
    setIsBgLoading(true);

    let res = await adminService.GET_REPORT_BY_COMPANY_ID(
      companySelectedRedux.id,
      "annual_checkup",
      `?begin_date=${e.begin_date}&end_date=${e.end_date}`
    );

    if (res && res.success) {
      const link = document.createElement("a");
      link.href = res.data.uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsBgLoading(false);
    } else {
      toast.error("เกิดข้อผิดพลาดไม่สามารถดาวน์โหลได้");
      setIsBgLoading(false);
    }
  };
  return (
    <HealthCheckOverviewStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="filter_health_top">
            <div className="box_filter_health">
              <div className="g_filter_label">
                <div>ภาพรวมพนักงานที่มีผลตรวจสุขภาพประจำปี</div>
                <div className="s_label_filter">
                  Employees annual health check overview
                </div>
              </div>
            </div>
            <div className="box_filter_health">
              <div className="box_filer_date_top">
                <div>แสดงสถิติข้อมูลปี</div>
                <div className="bw_date">
                  <Inputs.InputDatePikerCustom
                    theme_year
                    dateFormat="yyyy"
                    minDate={new Date("2021-01-01")}
                    maxDate={new Date()}
                    onChangeCustom={(e) => handleChangDate(e)}
                    showYearPicker
                    iconColor="#002366"
                    formatAD
                    initialValues={new Date()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box_date">
            <div>ข้อมูลล่าสุด ณ วันที่</div>
            <div className="date_blue">
              {`${moment(lastCheckup).locale("en").format("DD MMMM YYYY")} `}
            </div>
          </div>

          <div className="box_2_g">
            <div className="g_w424">
              <TotalEmployeeHealthCheckup
                data={data && data.summary && data.summary}
              />
            </div>
            <div className="g_full">
              <AgeDistribution
                data={data && data.age_group && data.age_group}
              />
            </div>
          </div>

          <div className="full_w_100">
            <HealthCheckDepartment data={dataDepartment && dataDepartment} />
          </div>
          <div />

          <div className="full_w_100 mt40">
            <ExportHealthCheck onDownload={handleDownload} />
          </div>
        </>
      )}
    </HealthCheckOverviewStyled>
  );
};

export default HealthCheckOverview;
