import React from "react";
import { CreateAccountContainerStyled } from "./styled";
import {
  Forms,
  Loading,
  Blocks,
  BGLoading,
  BoxDelete2,
  Icons,
  Modal,
} from "components";
import { ROUTE_PATH } from "utils/constants";
import { adminService } from "apiServices";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getObjectKey2 } from "utils/functions/getObjectKey";

class CreateAccountContainer extends React.Component {
  state = {
    isLoading: true,
    isActionDelete: false,
    roleOpt: [],
    titleShow: {
      th: "สร้างบัญชี",
      en: "Create Account",
    },
  };

  componentDidMount() {
    this.scrollToTop();
    this.checkPermission();
    this.fetchAllData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companySelectedRedux !== prevProps.companySelectedRedux) {
      this.handleBackClick();
    }
  }

  checkPermission = () => {
    const { authenRedux } = this.props;
    let checkPermission = getObjectKey2(
      authenRedux.admin_role.permissions,
      "admin_manage",
      "admin_manage_no_delete"
    );
    this.setState({
      perMissions: checkPermission,
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchAllData = async () => {
    let res = await Promise.all([this.fetchAdminRoles(), this.fetchDataEdit()]);

    if (res && res[0] && res[1]) {
      this.setState({
        roleOpt: res[0],
        initialValues: res[1] === true ? null : res[1],
        isLoading: false,
      });
    }
  };

  handleBackClick = () => {
    this.props.history.push(ROUTE_PATH.BACK_OFFICE_ACCOUNT);
  };

  fetchAdminRoles = async () => {
    let res = await adminService.GET_ADMIN_ROLES();

    if (res && res.success) {
      let tempData = [];
      res.data.map((e) =>
        tempData.push({
          label: e.name,
          value: e.id,
        })
      );

      return tempData;
    }
  };

  fetchDataEdit = async () => {
    if (this.props.match.params.id !== "create") {
      let res = await adminService.GET_ADMIN_BY_ID(this.props.match.params.id);
      if (res && res.success) {
        this.setState({
          titleShow: {
            th: "บัญชีของคุณ",
            en: "Your Account",
          },
        });

        return res.data;
      }
    } else {
      return true;
    }
  };

  onSubmit = async (values, callback) => {
    this.setState({
      isBgLoading: true,
    });

    console.log("yyyy");

    let params = {
      ...values,
      role: "company_admin",
      admin_role_id: values.role.value,
    };

    // if (!params.is_active) {
    //   delete params.is_active;
    // } else {
    //   params.is_active = values.is_active.value;
    // }

    let res =
      this.props.match.params.id === "create"
        ? await adminService.POST_ADMIN(
            this.props.companySelectedRedux?.id,
            params
          )
        : await adminService.PATCH_ADMIN(this.props.match.params.id, params);

    if (res && res.success) {
      this.setState({
        errorMessage: null,
      });
      toast.success(
        this.props.match.params.id === "create" ? "สร้างสำเร็จ" : "แก้ไขสำเร็จ"
      );
      this.handleBackClick();
    } else {
      this.setState({
        isBgLoading: false,
      });

      if (res.error_code === "EMAIL_EXISTS") {
        this.setState({
          errorMessage: {
            username: null,
            email: "* email ซ้ำ",
          },
        });
      } else if (res.error_code === "USERNAME_EXISTS") {
        this.setState({
          errorMessage: {
            username: "Username นี้ถูกใช้เเล้ว กรุณากรอกข้อมูลใหม่",
            email: null,
          },
        });
      }
      toast.error(
        this.props.match.params.id === "create"
          ? "สร้างไม่สำเร็จ"
          : "ไม่สามารถแก้ไขได้ เนื่องจากสิทธิ์การเข้าถึงน้อยกว่า"
      );
    }
  };

  handleDelete = async () => {
    let res = await adminService.DELETE_ADMIN(this.props.match.params.id);
    if (res && res.success) {
      toast.success("ลบสำเร็จ");
      this.handleBackClick();
    } else {
      toast.error("ลบไม่สำเร็จ");
    }
  };

  handlePopup = (e) => {
    if (e === "delete") {
      this.setState({
        isShowModal: (
          <BoxDelete2
            theme_box_delete
            handleConfirm={this.handleDelete}
            handleCancel={this.handleClickCloseModal}
            backgroundColor="#EC5E49"
            fontColor="#fff"
            textShow={
              <div
                style={{
                  color: "#EC5E49",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Icons.Delete width="24" height="24" />
                </div>
                <div>ยืนยันการลบบัญชีหรือไม่ ?</div>
              </div>
            }
          />
        ),
      });
    } else {
      this.setState({
        isShowModal: (
          <BoxDelete2
            theme_box_delete
            handleConfirm={this.hadleSendInvite}
            handleCancel={this.handleClickCloseModal}
            textShow={
              <div style={{ color: "#002366" }}>
                ยืนยันการส่งคำเชิญหรือไม่ ?
              </div>
            }
          />
        ),
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isShowModal: null,
    });
  };

  handleClearTextError = () => {
    this.setState({
      errorMessage: null,
    });
  };

  hadleSendInvite = async () => {
    this.setState({
      isBgLoading: true,
    });

    let res = await adminService.POST_ADMIN_RESET_PASSWORD(
      this.props.match.params.id
    );

    if (res && res.success) {
      toast.success("ส่งคำเชิญสำเร็จ");
      this.setState({
        isShowModal: null,
        isBgLoading: false,
        disabledButtonInvite: true,
      });
    } else {
      toast.error("ส่งคำเชิญไม่สำเร็จ");
      this.setState({
        isBgLoading: false,
      });
    }
  };

  render() {
    const {
      isLoading,
      initialValues,
      isBgLoading,
      roleOpt,
      perMissions,
      disabled,
      disabledButtonInvite,
      errorMessage,
      isShowModal,
    } = this.state;
    const { authenRedux, companySelectedRedux } = this.props;

    return (
      <CreateAccountContainerStyled>
        <BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="show_loading">
            <Loading theme_standard_loading />
          </div>
        ) : (
          <>
            <Blocks.Container>
              <Forms.CreateAccountForm
                onSubmit={this.onSubmit}
                handleDelete={this.handlePopup}
                initialValues={initialValues}
                handleBack={this.handleBackClick}
                companySelectedRedux={companySelectedRedux}
                hadleSendInvite={this.handlePopup}
                RoleOption={roleOpt}
                disabled={disabled}
                isCannotEditDelete={perMissions ? false : true}
                perMissions={perMissions}
                disabledButtonInvite={disabledButtonInvite}
                authenRedux={authenRedux}
                errorMessage={errorMessage}
                handleClearTextError={this.handleClearTextError}
                isProfile={
                  authenRedux &&
                  authenRedux.id === Number(this.props.match.params.id)
                    ? true
                    : false
                }
              />
              {/* <div className="layer_one">
                <Tabs.HeadTab
                  theme_standard_head_tab
                  title={titleShow.th}
                  subTitle={titleShow.en}
                  fontSize="32px"
                  fontSizeLabelButtom="18px"
                  fontFamilyBottom="noto_sans_thai_regular, noto_sans_regular"
                  fontColor="#013D59"
                  colorBottom="#9E9E9E"
                  margin_right="19px"
                  lineHeight="1.3"
                  typeBlank
                  onClick={this.handleBackClick}
                />
              </div>
              {isActionDelete ? (
                <div className="layer_delete">
                  <div className="label_del">
                    คุณต้องการลบบัญชีของคุณใช่หรือไม่ ?
                  </div>
                  <div className="btn_action">
                    <div className="btn_body">
                      <Buttons.BtnRoundShadow
                        theme_btn_round_shadow_green_3
                        label="ยืนยัน"
                        svg={<Icons.Check />}
                        fontSize="20px"
                        fontFamily="noto_sans_thai_medium, noto_sans_regular"
                        imgWidth="26px"
                        imgHeight="26px"
                        padding="10px 24px"
                        onClick={this.handleDelete}
                      />
                    </div>
                    <div className="btn_body">
                      <Buttons.BtnRoundShadow
                        theme_btn_round_shadow_orange_3
                        label="ยกเลิก"
                        svg={<Icons.Wrong />}
                        fontSize="20px"
                        fontFamily="noto_sans_thai_medium, noto_sans_regular"
                        imgWidth="29px"
                        imgHeight="29px"
                        padding="10px 24px"
                        onClick={this.handleClickCloseModal}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="layer_two">
                  <Forms.CreateAccountForm
                    onSubmit={this.onSubmit}
                    handleDelete={this.handlePopup}
                    initialValues={initialValues}
                    RoleOption={roleOpt}
                    isCannotEditDelete={perMissions ? false : true}
                  />
                </div>
              )} */}
            </Blocks.Container>
          </>
        )}
        <Modal
          theme_modal_standard
          isShow={isShowModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {isShowModal}
        </Modal>
      </CreateAccountContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
  companySelectedRedux: state.companySelectedRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountContainer);
