import React, { useState, useEffect } from "react";
import { AbnormalTrendStyled } from "./styled";
import { scrollToTopPageFN } from "utils/functions/scrollTopPage";
import { adminService } from "apiServices";
import { Loading, BGLoading, Inputs, Icons } from "components";
import theme from "styles/theme.json";
import { GraphTemplate } from "widgets";
import { useSelector } from "react-redux";

const AbnormalTrend = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isBgLoading, setIsBgLoading] = useState();
  const [_checktotal_abnormal_count, _setChecktotal_abnormal_count] = useState({
    total_abnormal_count: true,
    male_abnormal_count: true,
    female_abnormal_count: true,
  });
  const [typeCheck, setTypeCheck] = useState([
    "total_abnormal_count",
    "male_abnormal_count",
    "female_abnormal_count",
  ]);
  const { companySelectedRedux } = useSelector((state) => state);
  const [data, setData] = useState();

  useEffect(() => {
    scrollToTopPageFN();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);
    fetchAbnormalDataLab();
  }, [companySelectedRedux]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAbnormalDataLab = async (stringQuery) => {
    let abnormal_type = [
      "bmi",
      "bp",
      "fbs",
      "cholesterol",
      "tg",
      "ldl",
      "hdl",
      "bun",
      "sgot",
      "uric",
    ];

    let res = await Promise.all(
      abnormal_type.map((type) =>
        adminService.GET_HEALTH_CHECKUP_ABNORMAL_LAB(
          companySelectedRedux?.id,
          type
        )
      )
    );

    if (res) {
      setData({
        bmi: res[0]?.data,
        bp: res[1]?.data,
        fbs: res[2]?.data,
        cholesterol: res[3]?.data,
        tg: res[4]?.data,
        ldl: res[5]?.data,
        hdl: res[6]?.data,
        bun: res[7]?.data,
        sgot: res[8]?.data,
        uric: res[9]?.data,
      });
      setIsLoading(false);
    }
  };

  const handlSelectfilter = (e, value) => {
    setIsBgLoading(true);
    let tempCheck = _checktotal_abnormal_count;
    tempCheck[value] = e;
    const tempCheckTrue = Object.keys(tempCheck).filter(
      (key) => tempCheck[key] === true
    );
    _setChecktotal_abnormal_count(tempCheck);
    setTypeCheck(tempCheckTrue);
    setTimeout(() => {
      setIsBgLoading(false);
    }, 1500);
  };

  return (
    <AbnormalTrendStyled>
      <BGLoading visible={isBgLoading} />
      {isLoading ? (
        <div className="loading_layout">
          <Loading theme_standard_loading />
        </div>
      ) : (
        <>
          <div className="filter_health_top">
            <div className="box_filter_health">
              <div className="g_filter_label">
                <div>
                  สถิติเปรียบเทียบแนวโน้มค่าผลแล็บผิดปกติของพนักงาน (แสดงข้อมูล
                  5 ปี)
                </div>
                <div className="s_label_filter">
                  Trends in employee abnormal lab results (showing 5 years)
                </div>
              </div>
            </div>
            <div className="box_filter_health">
              <div className="box_filer_top">
                <div>แสดงสถิติข้อมูล</div>
                <div className="chack_box">
                  <Inputs.InputCheckBox
                    theme_color_custom
                    label={`ทั้งหมด`}
                    value="total_abnormal_count"
                    onChangeCustom={(e) =>
                      handlSelectfilter(e, "total_abnormal_count")
                    }
                  />
                  <Inputs.InputCheckBox
                    theme_color_custom
                    bgcolor={theme.COLORS.BLUE_27}
                    label={`เพศชาย`}
                    value={"male_abnormal_count"}
                    onChangeCustom={(e) =>
                      handlSelectfilter(e, "male_abnormal_count")
                    }
                  />
                  <Inputs.InputCheckBox
                    theme_color_custom
                    bgcolor={theme.COLORS.PINK_14}
                    label={`เพศหญิง`}
                    value={"female_abnormal_count"}
                    onChangeCustom={(e) =>
                      handlSelectfilter(e, "female_abnormal_count")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="layout_warn">
            <div className="box_warn">
              <div className="svg">
                <Icons.Warn />
              </div>
              <div>:</div>
              <div>
                จำนวนคนที่มีผลแล็บผิดปกติที่มากกว่าครึ่งของจำนวนพนักงาน
                ที่มีค่าแล็บในบริษัท
              </div>
            </div>
          </div>
          <div className="box_2">
            <GraphTemplate
              titleChart="ดัชนีมวลกาย"
              subTitleChar="BMI"
              data={data?.bmi}
              selectFilter={typeCheck}
            />
            <GraphTemplate
              titleChart="ความดันโลหิต"
              subTitleChar="Blood Pressure"
              data={data?.bp}
              selectFilter={typeCheck}
            />
          </div>
          <div className="box_2">
            <GraphTemplate
              titleChart="น้ำตาลในเลือด"
              subTitleChar="Blood Sugar (FBS)"
              data={data?.fbs}
              selectFilter={typeCheck}
            />
            <GraphTemplate
              titleChart="คอเลสเตอรอล"
              subTitleChar="Cholesterol"
              data={data?.cholesterol}
              selectFilter={typeCheck}
            />
          </div>
          <div className="box_2">
            <GraphTemplate
              titleChart="ไตรกลีเซอไรด์"
              subTitleChar="Triglyceride"
              data={data?.tg}
              selectFilter={typeCheck}
            />
            <GraphTemplate
              titleChart="ไขมันเลว"
              subTitleChar="LDL"
              data={data?.ldl}
              selectFilter={typeCheck}
            />
          </div>
          <div className="box_2">
            <GraphTemplate
              titleChart="ไขมันดี"
              subTitleChar="HDL"
              data={data?.hdl}
              selectFilter={typeCheck}
            />
            <GraphTemplate
              titleChart="การทำงานของไต"
              subTitleChar="Renal Function"
              data={data?.bun}
              selectFilter={typeCheck}
            />
          </div>
          <div className="box_2">
            <GraphTemplate
              titleChart="สมรรถภาพตับ"
              subTitleChar="Liver Function"
              data={data?.sgot}
              selectFilter={typeCheck}
            />
            <GraphTemplate
              titleChart="สารกรดยูริก"
              subTitleChar="Uric Acid"
              data={data?.uric}
              selectFilter={typeCheck}
            />
          </div>
        </>
      )}
    </AbnormalTrendStyled>
  );
};

export default AbnormalTrend;
