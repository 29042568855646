import styled from "styled-components";

export const InputDropDownFilterStyled = styled.div`
  .input_container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    .label {
      margin-bottom: 5px;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    }
    .input_block {
      position: relative;
      cursor: pointer;
      .input_dropdown {
        display: flex;
        align-items: center;
        column-gap: 15px;
        height: 48px;
        padding: 0 16px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border-radius: 8px;
        .input_value {
          flex: 1;
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
        }
        .icon_wrap {
          padding-top: 6px;
        }
      }
      .option_container {
        max-height: 300px;
        overflow: auto;
        position: absolute;
        z-index: 4;
        top: 52px;
        right: 0px;
        left: 0px;
        padding: 10px 16px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_2};
        .layout_1 {
          .layout_2 {
            .layout3 {
            }
          }
        }

        .title {
          padding: 12px 0px;
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.PURPLE_2};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.h1 {
            color: ${({ theme }) => theme.COLORS.BLUE_12};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          }
          &.h2 {
            padding: 12px 20px;
            color: ${({ theme }) => theme.COLORS.BLUE_12};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
          }
          &.h3 {
            padding: 12px 40px;
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            font-size: ${({ theme }) => theme.FONT.SIZE.S16};
            font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
          }
        }
        .detail {
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.PURPLE_2};
        }
      }
    }
  }

  .theme_login {
    .input_dropdown {
      width: 330px;
    }
  }
`;
