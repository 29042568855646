import styled from "styled-components";

export const AbnormalTrendStyled = styled.div`
  .loading_layout {
    height: calc(100vh - 186px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter_health_top {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
    /* margin-top: 40px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box_filter_health {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .g_filter_label {
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.MEDIUM};
        .s_label_filter {
          color: ${({ theme }) => theme.COLORS.GRAY_24};
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        }
      }
      .box_filer_top {
        display: flex;
        align-items: center;
        column-gap: 16px;
        color: ${({ theme }) => theme.COLORS.GRAY_23};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.BOLD};
        .chack_box {
          width: 346px;
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_22};
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 10px 0;
        }
      }
    }
  }
  .layout_warn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .box_warn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      background: ${({ theme }) => theme.COLORS.RED_11};
      color: ${({ theme }) => theme.COLORS.GRAY_24};
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
      padding: 6px 12px;
      column-gap: 5px;
      .svg {
        display: flex;
        align-items: center;
      }
    }
  }

  .box_2 {
    display: flex;
    margin-top: 24px;
    column-gap: 14px;
    overflow: auto;
    margin-bottom: -8px;
  }
`;
