import { GraphTemplateStyled } from "./styled";
import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import theme from "styles/theme.json";
import "moment-timezone";
import { Blocks, Typographys } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const GraphTemplate = ({ data, titleChart, subTitleChar, selectFilter }) => {
  const [dataSeries, setdataSeries] = useState([]);
  const [dataAnnotations, setDataAnnotations] = useState([]);
  const [category, setCategory] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    let tempData = [];
    let tempAnnot = [];
    let tempcategory = [];
    let tempcolors = [];

    selectFilter?.forEach((type) => {
      let dataPoints = [];
      let tempTotalAmount = [];
      let tempAmount = [];

      dataPoints.push(null);
      tempTotalAmount.push(null);
      tempAmount.push(null);

      data.forEach((e) => {
        dataPoints.push(
          (type === "total_abnormal_count"
            ? RenderCommaMoney(Number(e.checkup_count).toFixed(0))
            : type === "male_abnormal_count"
            ? RenderCommaMoney(Number(e.male_count).toFixed(0))
            : RenderCommaMoney(Number(e.female_count).toFixed(0))) === "0"
            ? 0
            : Number(
                (
                  (Number(e?.[type]) /
                    (type === "total_abnormal_count"
                      ? RenderCommaMoney(Number(e.checkup_count).toFixed(0))
                      : type === "male_abnormal_count"
                      ? RenderCommaMoney(Number(e.male_count).toFixed(0))
                      : RenderCommaMoney(Number(e.female_count).toFixed(0)))) *
                  100
                ).toFixed(2)
              )
        );
        tempTotalAmount.push(
          type === "total_abnormal_count"
            ? RenderCommaMoney(Number(e.checkup_count).toFixed(0))
            : type === "male_abnormal_count"
            ? RenderCommaMoney(Number(e.male_count).toFixed(0))
            : RenderCommaMoney(Number(e.female_count).toFixed(0))
        );

        tempAmount.push(
          (type === "total_abnormal_count"
            ? RenderCommaMoney(Number(e.checkup_count).toFixed(0))
            : type === "male_abnormal_count"
            ? RenderCommaMoney(Number(e.male_count).toFixed(0))
            : RenderCommaMoney(Number(e.female_count).toFixed(0))) === "0"
            ? 0
            : RenderCommaMoney(Number(e?.[type]).toFixed(0))
        );
      });

      dataPoints.push(null);
      tempTotalAmount.push(null);
      tempAmount.push(null);

      tempData.push({
        name: type,
        data: dataPoints,
        amount: tempAmount,
        totalAmount: tempTotalAmount,
      });

      data?.forEach((item) => {
        if (item[type]) {
          tempAnnot.push({
            x: item?.year,
            y:
              item.checkup_count === 0
                ? 0
                : Number(
                    (
                      (Number(item?.[type]) /
                        (type === "total_abnormal_count"
                          ? RenderCommaMoney(
                              Number(item.checkup_count).toFixed(0)
                            )
                          : type === "male_abnormal_count"
                          ? RenderCommaMoney(Number(item.male_count).toFixed(0))
                          : RenderCommaMoney(
                              Number(item.female_count).toFixed(0)
                            ))) *
                      100
                    ).toFixed(2)
                  ),
          });
        }
      });

      if (type === "total_abnormal_count") {
        tempcolors.push("#745A9D");
      } else if (type === "male_abnormal_count") {
        tempcolors.push("#4362A4");
      } else if (type === "female_abnormal_count") {
        tempcolors.push("#DA5695");
      }
    });

    tempcategory.push("");
    data?.forEach((item) => {
      tempcategory.push(item?.year);
    });

    setCategory(tempcategory);
    setDataAnnotations(tempAnnot);
    setdataSeries(tempData);
    setColors(tempcolors);
  }, [data, selectFilter]);

  return (
    <GraphTemplateStyled>
      <div className="container_graph_display">
        <div className="top_layout">
          <div className="left_men_layout">
            <Typographys.TopBottom
              theme_left_align
              label={titleChart}
              labelBottom={subTitleChar}
              fontSize={theme.FONT.SIZE.S18}
              fontSizeLabelButtom={theme.FONT.SIZE.S14}
              fontFamilyTop={theme.FONT.STYLE.BOLD}
              fontFamilyBottom={theme.FONT.STYLE.REGULAR}
            />
          </div>
          <div className="right_men_layout">
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_health_check
                label="ทั้งหมด"
                bgColor={theme.COLORS.PURPLE_8}
                showRound
              />
            </div>
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_health_check
                label="เพศชาย"
                bgColor={theme.COLORS.BLUE_27}
                showRound
              />
            </div>
            <div className="show_being">
              <Blocks.BoxWellbeing
                theme_health_check
                label="เพศหญิง"
                bgColor={theme.COLORS.PINK_14}
                showRound
              />
            </div>
          </div>
        </div>
        <div className="body_graph">
          {data ? (
            <>
              <div className="top_title_g">
                <div>จำนวน (%)</div>
              </div>
              <div className="right_title_g">จำนวน ปี</div>
              <Chart
                options={{
                  chart: {
                    toolbar: {
                      show: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  tooltip: {
                    custom: function ({
                      series,
                      seriesIndex,
                      dataPointIndex,
                      w,
                    }) {
                      var dataToolTip =
                        w.globals.initialSeries[seriesIndex].data[
                          dataPointIndex
                        ];

                      var namedata =
                        w.globals.initialSeries[seriesIndex].name ===
                        "total_abnormal_count"
                          ? "ทั้งหมด"
                          : w.globals.initialSeries[seriesIndex].name ===
                            "female_abnormal_count"
                          ? "เพศหญิง"
                          : w.globals.initialSeries[seriesIndex].name ===
                            "male_abnormal_count"
                          ? "เพศชาย"
                          : w.globals.initialSeries[seriesIndex].name;

                      var tooltipContent =
                        '<div class="box_hover"> <div class="title">';

                      if (namedata === "ทั้งหมด") {
                        tooltipContent += '<div class="round all"></div>';
                      } else if (namedata === "เพศหญิง") {
                        tooltipContent += '<div class="round female"></div>';
                      } else if (namedata === "เพศชาย") {
                        tooltipContent += '<div class="round male"></div>';
                      }
                      tooltipContent +=
                        '<div class="name_title">' +
                        namedata +
                        "</div>" +
                        " ผิดปกติ " +
                        dataToolTip +
                        "% </div>" +
                        '<div class="text_datail">' +
                        "(จำนวน " +
                        dataSeries?.[seriesIndex]?.amount?.[dataPointIndex] +
                        " คน จากจำนวนทั้งหมด " +
                        dataSeries?.[seriesIndex]?.totalAmount?.[
                          dataPointIndex
                        ] +
                        " คน)" +
                        " </div>";

                      // return dataToolTip > 50 ? tooltipContent : null;
                      return tooltipContent;
                    },
                  },

                  xaxis: {
                    categories: category,
                    tooltip: {
                      enabled: false,
                    },
                    labels: {
                      show: true,
                      style: {
                        fontSize: "12px",
                        colors: theme.COLORS.GRAY_23,
                        fontFamily: theme.FONT.STYLE.REGULAR,
                      },
                    },
                    axisBorder: {
                      show: true,
                      color: "#5C5C5C",
                    },
                  },
                  yaxis: {
                    max: 100,
                    min: 0,
                    tickAmount: 5,
                    labels: {
                      show: true,
                      style: {
                        fontSize: "12px",
                        colors: theme.COLORS.GRAY_23,
                        fontFamily: theme.FONT.STYLE.REGULAR,
                      },
                      formatter: function (value) {
                        return `${value}%`;
                      },
                      hideOverlappingLabels: false,
                    },
                    axisBorder: {
                      show: true,
                      color: "#5C5C5C",
                    },
                  },

                  colors: colors,
                  markers: {
                    size: 4,
                    strokeWidth: 0,
                  },
                  annotations: {
                    points: [
                      // Add annotations dynamically for points where y >= 50
                      ...dataAnnotations
                        .map((point) => {
                          if (point.y > 50) {
                            return {
                              x: point.x,
                              y: point.y,
                              marker: {
                                size: 0,
                              },
                              image: {
                                path: "/assets/images/logo/roundWarnning.svg",
                                offsetX: 0,
                                offsetY: -20,
                              },
                            };
                          }
                          return null;
                        })
                        .filter(Boolean),
                    ],
                  },
                  stroke: {
                    width: 1,
                  },
                  grid: {
                    borderColor: "#F3F3F3",
                    strokeDashArray: 0,
                    xaxis: {
                      lines: {
                        show: false,
                      },
                    },
                    yaxis: {
                      lines: {
                        show: true,
                      },
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={dataSeries}
                type="line"
                height={328}
                width="100%"
              />
            </>
          ) : (
            <div className="lbl_empty">ไม่มีข้อมูล</div>
          )}
        </div>
      </div>
    </GraphTemplateStyled>
  );
};

export default GraphTemplate;
